import React from 'react'
import { graphql } from 'gatsby'
import CabaretLayout from '../../../components/cabaretlayout'
import CabaretInfosNavigation from '../../../components/Infos/CabaretInfos/CabaretInfosNavigations'
import CabaretSponsors from '../../../slices/CabaretSponsors'

const CabaretInfosPartenaires = ({ data }) => (
    <CabaretLayout>
        <div className='cabaret-infos'>

            <CabaretInfosNavigation />

            <h1>Partenaires</h1>
            <div className='px-4'>
              <CabaretSponsors data={data}/>
            </div>

        </div>
    </CabaretLayout>
)

export default CabaretInfosPartenaires

export const cabaretSponsorResult = graphql`
query {
    allPrismicCabaretsponsor {
        edges {
          node {
            data {
              image {
                alt
                gatsbyImageData
              }
              link {
                url
              }
              name {
                text
              }
              category
              priority
            }
          }
        }
      }
    }
`
